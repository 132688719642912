import { Context, useMutation, useTranslation } from '@obvio/app'
import { CheckboxInput, Form, Stack, TextInput } from '@obvio/ui'
import { useCallback } from 'react'

import { Arrow } from '../Arrow'
import { NavForm } from './NavForm'
import { FormContent } from './NavFormEvent/components'
import {
  SignTitle,
  StyledCheckboxInput,
  StyledSubmit,
  Title,
} from './NavFormRestaurant/components'
import { globalStore } from '@/pages/_app'

import type { StateSetter } from '@obvio/utils'
import type { ReactElement } from 'react'

type NavFormNewsletterInnerProps = {
  setConfirmed: StateSetter<boolean>
}

function NavFormNewsletterInner({
  setConfirmed,
}: NavFormNewsletterInnerProps): ReactElement {
  const [newsletterMutation] = useMutation('registerNewsletter')

  const handleSubmit = useCallback(
    async (data: {
      vineyard: boolean
      restaurant: boolean
      spa: boolean
      name: string
      email: string
    }) => {
      await newsletterMutation(data)
      setConfirmed(true)
    },
    [newsletterMutation, setConfirmed],
  )

  return (
    <FormContent>
      <Context name="navForm">
        <Stack kind="vertical" spacing="large">
          <SignTitle>ZAPISZ SIĘ NA</SignTitle>
          <Title tag="h2">
            <b>NEWSLETTER 511</b>
          </Title>
          <Stack kind="vertical" spacing="large">
            <Form onSubmit={handleSubmit} contextOnly>
              <span>Wybierz interesujące Cię obszary tematyczne</span>
              <Stack>
                <CheckboxInput label="Winnica 511" name="vineyard" />
                <CheckboxInput
                  label="Restauracja & Bar 511"
                  name="restaurant"
                />
                <CheckboxInput label="Organic medi SPA 511" name="spa" />
              </Stack>
              <TextInput label="Imię i nazwisko" name="name" />
              <TextInput label="Email" name="email" />
              <StyledCheckboxInput
                noSubmit
                optional={false}
                name="tos"
                label="Zapoznał_m się z regulaminem zapisów i wyrażam zgodę na kontakt telefoniczny przez Poziom 511 Sp. z o.o. z siedzibą w Ogrodzieńcu w celach realizacji usług i sprzedaży produktów."
              />
              <StyledSubmit kind="transparent">
                <Stack>
                  <span>Zapisz</span>
                  <Arrow direction="right" length="7rem" />
                </Stack>
              </StyledSubmit>
            </Form>
          </Stack>
        </Stack>
      </Context>
    </FormContent>
  )
}

export function NavFormNewsletter(): ReactElement | null {
  const { t } = useTranslation()
  const [{ newsletter }, dispatch] = globalStore('newsletter')

  if (!newsletter) {
    return null
  }

  return (
    <NavForm
      title="NEWSLETTER"
      close={() => dispatch('NEWSLETTER', false)}
      confirmText={t('newsletterSignup.form.success')}
    >
      {(setConfirmed) => <NavFormNewsletterInner setConfirmed={setConfirmed} />}
    </NavForm>
  )
}
