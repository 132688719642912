import { AssetsProvider, Head, RESOURCE_LINKS, useRouter } from '@obvio/app'
import { resolveResourceLinks, serverClient } from '@obvio/server'
import dynamic from 'next/dynamic'
import { NextSeo } from 'next-seo'

import { schema } from '@/api/schema'
import { Layout } from '@/components/Layout'
import { NavFormEvent } from '@/components/NavForms/NavFormEvent'
import { NavFormNewsletter } from '@/components/NavForms/NavFormNewsletter'
import { Renderer } from '@/components/Renderer'
import { RESOURCE_PREFIXES } from '@/constants'
import { serverPrefetchQueries } from '@/constants/queries'

import type { LayoutType } from '@/components/Layout'
import type { RendererData } from '@obvio/layout/renderer'
import type {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next'
import type { ReactElement } from 'react'

const LazyInfoPageSuffix = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "LazyInfoPageSuffix" */
    '@/components/InfoPageSuffix'
  )
  return comp.InfoPageSuffix
})

function Page({
  page,
}: {
  page: {
    id: string
    slug: string
    data: RendererData<any, any>[]
    layoutRef: { title: LayoutType }
    seoTitle?: string
    seoDescription?: string
  }
}): ReactElement {
  const { hasParam } = useRouter()
  const canonical = `https://${process.env.NEXT_PUBLIC_DOMAIN ?? ''}/${page.slug ?? ''
    }`

  const isInfo = page.layoutRef.title === 'Informacje'

  return (
    <>
      <NextSeo
        canonical={canonical}
        {...(page.seoTitle && { title: page.seoTitle })}
        {...(page.seoDescription && { description: page.seoDescription })}
      />
      {page.slug === '' ? (
        <Head>
          <meta
            name="google-site-verification"
            content="mJpXX-8exFdoLD6VeTKeFqi0TF6mpa009gY-_KIh550"
          />
        </Head>
      ) : null}
      <Layout type={page.layoutRef.title}>
        <AssetsProvider path={`/page/${page.id}`}>
          {hasParam('id') ? (
            <div />
          ) : (
            <Renderer key={page.id} data={page.data} />
          )}
          {isInfo ? <LazyInfoPageSuffix /> : <div />}
          <NavFormEvent />
          <NavFormNewsletter />
        </AssetsProvider>
      </Layout>
    </>
  )
}

export async function getStaticProps(
  ctx: GetStaticPropsContext<{ slug: string[] }>,
): Promise<
  GetStaticPropsResult<{
    page: {
      id: string
      data: string
      layoutRef: { title: string }
    }
  }>
> {
  const client = serverClient(schema, { ...ctx, isIsr: true })

  const slug = ctx?.params?.slug ?? ['']

  const page = await client.query('getPage', {
    args: {
      slug: slug.join('/'),
    },
    select: {
      slug: true,
      data: true,
      layoutRef: {
        title: true,
      },
      seoTitle: true,
      seoDescription: true,
    },
  })

  await client.query('getPages', {
    select: {
      id: true,
      slug: true,
    },
  })

  await serverPrefetchQueries(page?.data ?? [], client)

  if (!page) {
    return { notFound: true }
  }

  return {
    ...client.ssrData({
      page,
      [RESOURCE_LINKS]: await resolveResourceLinks(
        page.data,
        RESOURCE_PREFIXES,
      ),
    }),
    revalidate: 600,
  }
}

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  const client = serverClient(schema, { isAdmin: true })

  const pages = await client.query('getPages', {
    select: {
      slug: true,
    },
  })

  return {
    paths: pages
      .filter(({ slug }) => slug !== 'pokoje')
      .map(({ slug }: { slug: string }) => ({
        params: { slug: slug.split('/') },
      })),
    fallback: 'blocking',
  }
}

export default Page
